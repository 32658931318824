// Libraries
import React from "react";
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import PropTypes from "prop-types";
const { useRef } = React;
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Badge from "@material-ui/core/Badge";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import BarChartIcon from "@material-ui/icons/BarChart";
import PeopleIcon from "@material-ui/icons/People";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import HistoryIcon from "@material-ui/icons/History";
import EditIcon from "@material-ui/icons/Edit";

// API
import {
  listClients,
  postClientCreateview,
  listClientsFromUser,
  listClientsFromReseller,
} from "../api/Clients";
import { getUserStagedInvoices } from "../api/Store";
import { listResellers } from "../api/Resellers";
import { deleteStagedLineItem } from "../api/StagedLineItems";

// Custom components
import { Context } from "../context/UserState";
import CreateClientModal from "./client/CreateClientModal";
import SnackbarMessage from "./SnackbarMessage";
import { ResellerSelector } from "./ResellerSelector";
import CreateResellerDialog from "./CreateResellerDialog";
import { ClientSelector } from "./ClientSelector";
import { checkTestingUser } from "../api/Users";

const drawerWidth = 190;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
    paddingTop: "65px",
  },
  drawerContainerSmall: {
    overflow: "auto",
  },
  clientListItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    //whiteSpace: "nowrap",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));
const userAccessClientSelector = [
  "clientadmin",
  "clientproductmanager",
  "clientusermanager",
  "clientorderapprover",
];
const defaultTheme = () => {
  let user_role = localStorage.getItem("role");
  let defaulColors = false;
  if (user_role === "customer") {
    const colors = JSON.parse(localStorage.getItem("themeColors"));
    defaulColors = colors;
  }
  return defaulColors;
};

export default function DashboardLayout({ routes, ...props }) {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [open, setOpenCreateClientModal] = React.useState(false);
  const [smallOpen, setSmallOpen] = useState(false);
  const [image, setImage] = useState({});
  const [client_image_url, setClientImageUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const initial_invoice = {
    InvoiceId: "",
    order_invoice: null,
    staged_line_items: [],
  };
  const [currentInvoice, setCurrentInvoice] = useState(initial_invoice);
  // Check URL
  let route_match = useRouteMatch("/app/client/:client_id");
  const [reaload_resellers, setReloadResellers] = useState(true);
  // Initialize user_role
  let user_role = localStorage.getItem("role");
  const [user_resellers, setUserResellers] = useState([]);
  const [reseller, setReseller] = useState({ name: "..." });
  //check if user is a test's user
  const [test_user, setTestUser] = useState(false);
  const [selected_client, setSelectedClient] = useState(null);
  const [selectorClients, setSelectorClients] = useState([]);

  // Save original theme
  const original_theme = useTheme();
  // Controling current theme colors
  const [theme_colors, setThemeColors] = useState(
    defaultTheme() || {
      primary: original_theme.palette.primary.main,
      secondary: original_theme.palette.secondary.main,
    }
  );
  // Change flag to reload resellers list
  const reloadResellers = (refresh_data = false) => {
    setReloadResellers(!reaload_resellers);
    if (refresh_data) {
      getClients();
      childRef.current ? childRef.current.refreshData() : null;
      history.push("/app/products");
    }
  };

  //Get clients
  const getClients = () => {
    // List clients
    listClients()
      .then((result) => {
        if (result.data.clients) {
          setClients(result.data.clients);
          setLoading(false);
        } else if (
          result.errors[0].message &&
          result.errors[0].message == "Invalid token."
        ) {
          logout();
        }
      })
      .catch((error) => {
        console.log("error -> ", error);
      });
  };

  //List clients only once at load
  useEffect(async () => {
    getClients();
    let user_id = localStorage.getItem("user");
    let isTestUser = await checkTestingUser(user_id);
    setTestUser(isTestUser);
    (isTestUser
      ? listClientsFromReseller()
      : listClientsFromUser({ user_id: user_id })
    ).then((result) => {
      const clients = result.data.clients;
      const client = clients.length > 0 ? clients[0] : [];
      setSelectorClients(clients);
      ClientData(client);
    });
    //set initial shopping cart
    getUserStagedInvoices().then((res) => {
      res.data.stagedInvoices.forEach((invoice) => {
        if (!invoice.order_invoice) {
          setCurrentInvoice(invoice);
          return;
        }
      });
    });
    //load theme for customer
    if (user_role === "customer") {
      //Get Theme Colors
      const colors = JSON.parse(localStorage.getItem("themeColors"));
      if (colors) {
        setThemeColors(colors);
      }
    }
  }, []);

  //removing products from current client
  const removingProducts = () => {
    if (test_user) {
      //check the current staged invoice
      getUserStagedInvoices().then((res) => {
        let currentStagedInvoice = false;
        if (res.data && res.data.stagedInvoices) {
          res.data.stagedInvoices.forEach((invoice) => {
            if (!invoice.order_invoice) {
              currentStagedInvoice = invoice;
            }
          });
          //removing products
          if (
            currentStagedInvoice &&
            currentStagedInvoice.staged_line_items.length > 0
          ) {
            const staged_line_items = currentStagedInvoice.staged_line_items;
            staged_line_items.forEach((item) => {
              deleteStagedLineItem(item.id).then((result) => {
                if (result) {
                  //set initial shopping cart
                  getUserStagedInvoices().then((res) => {
                    res.data.stagedInvoices.forEach((invoice) => {
                      if (!invoice.order_invoice) {
                        setCurrentInvoice(invoice);
                        return;
                      }
                    });
                  });
                }
              });
            });
          }
        }
      });
    }
  };

  //Get client data
  const ClientData = (value = null) => {
    test_user ? removingProducts() : "";
    const client = value ? value : selected_client;
    let url = client.client_image ? client.client_image.url : null;
    setClientImageUrl(url);
    setSelectedClient(client);
  };

  // Execute this on every render of this component
  useEffect(() => {
    // Reset scroll to the top
    window.scrollTo(0, 0);
    // Save current location
    if (state.token) {
      localStorage.setItem("pathname", props.location.pathname);
    }
    // Check if you are logged in
    if (!state.token) {
      let token = localStorage.getItem("token");
      let currentLocation = localStorage.getItem("pathname");
      if (token) {
        if (currentLocation != props.location.pathname) {
          localStorage.setItem("pathname", props.location.pathname);
          history.push("/");
        }
      }
      history.push("/");
    }
    // Refresh user_role
    user_role = localStorage.getItem("role");
  });

  // List all resellers only at first load and wheh reaload_resellers flag changes
  useEffect(() => {
    let isMounted = true;
    listResellers()
      .then((response) => {
        if (isMounted) {
          let reseller_id = localStorage.getItem("reseller_id");
          let resellers = response.data.resellers;
          setUserResellers(resellers);
          if (resellers.length > 0) {
            let found_reseller = resellers.find(
              (reseller) => reseller.id == reseller_id
            );
            setReseller(found_reseller);
          }
        }
        return () => {
          isMounted = false;
        };
      })
      .catch((error) => {
        console.log(error);
        setError({
          open: true,
          message: `Error listing resellers`,
          severity: "error",
        });
      });
  }, [reaload_resellers]);

  const logout = () => {
    // Clear local storage
    localStorage.clear();
    // Clear context state
    dispatch({
      type: "SET_TOKEN",
      payload: null,
    });
  };

  //Initial Client Data
  const inital_client = {
    name: "",
    uniqueId: "",
    primary_color: "#000000",
    secondary_color: "#000000",
    client_image: "",
    ordering_mode: "Default",
    acumatica_setting: "Resellersettings",
  };

  //Open CreateClientModal
  function ShowCreateUserModal() {
    setOpenCreateClientModal(true);
  }

  // Close client dialog
  const handleClose = () => {
    setSaving(false);
    setOpenCreateClientModal(false);
    setImage({});
    formik.resetForm({
      values: inital_client,
    });
  };

  // Manage errors
  const [error, setError] = useState({
    message: "",
    severity: "error",
    open: false,
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "error",
    });
  };

  /**
   * Formik client validation schema
   */
  const validationSchema = yup.object({
    name: yup.string("Client Name").required("Client Name is required"),
    uniqueId: yup
      .string("Client UniqueId")
      .required("Client UniqueId is required"),
    primary_color: yup.string("Client Primary Color"),
    secondary_color: yup.string("Client Primary Color"),
    client_image: yup.string("Client image url"),
    acumatica_setting: yup.string("Acumatica setting"),
  });

  // Create client formik
  const formik = useFormik({
    initialValues: inital_client,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSaving(true);
      if (values) {
        if (values.name) {
          values.name = values.name.trim();
        }
        if (values.uniqueId) {
          values.uniqueId = values.uniqueId.trim();
        }
      }
      if (image.files) {
        let formData = new FormData();
        formData.append("files", image.files);
        axios
          .post(`${process.env.REACT_APP_REST_URL}/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            values.client_image = res.data[0].id;
            CreateClient(values);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        CreateClient(values);
      }
    },
  });

  //Create client
  const CreateClient = (values) => {
    postClientCreateview(values)
      .then((xhr) => {
        if (xhr.errors) {
          // Get error
          const errorMessage = xhr.errors[0].message;
          setError({
            open: true,
            message: `Error: ${
              errorMessage == "Duplicate entry"
                ? "Duplicate Client Unique ID"
                : errorMessage
            }`,
            severity: "error",
          });
        } else {
          getClients();
          handleClose();
          setError({
            open: true,
            message: "Client Created Succesfully",
            severity: "success",
          });
          formik.resetForm();
        }
      })
      .catch((xhr) => {
        console.log(xhr);
      })
      .finally(() => setSaving(false));
  };

  const toggleMenu = () => {
    setSmallOpen(!smallOpen);
  };

  //get products in cart whenever it changes
  const getUserCart = () => {
    getUserStagedInvoices().then((res) => {
      if (res.data.stagedInvoices.length < 1) {
        setCurrentInvoice(initial_invoice);
      } else {
        res.data.stagedInvoices.forEach((invoice) => {
          if (!invoice.order_invoice) {
            setCurrentInvoice(invoice);
            return;
          } else {
            setCurrentInvoice(initial_invoice);
          }
        });
      }
    });
  };

  //Redirects to the home view for specific users
  const goHome = () => {
    if (user_role === "customer") {
      history.push(`/store/products`);
    } else {
      if (localStorage.getItem("client_id")) {
        history.push(
          `/app/client/${localStorage.getItem("client_id")}/store/products`
        );
      }
    }
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: theme_colors.primary,
      },
      secondary: {
        main: theme_colors.secondary,
      },
      type: theme_colors.primary.includes("#fff") ? "dark" : "light",
    },
  });

  // Get ref to some child component
  const childRef = useRef();
  return state.token ? (
    <ThemeProvider theme={theme}>
      {/* Appbar */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid
            id="toolbar_container"
            container
            alignItems="center"
            justify="space-between"
          >
            {/*Show this only in xs Client selector for test's users or users to have access */}
            {(test_user ||
              (userAccessClientSelector.includes(user_role) &&
                selectorClients.length > 1)) && (
              <Hidden smUp>
                <Grid
                  item
                  xs={12}
                  id="client-selector"
                  style={{ paddingTop: "15px" }}
                >
                  <ClientSelector
                    clients={selectorClients}
                    client={selected_client}
                    selectedClient={setSelectedClient}
                    clientData={ClientData}
                  />
                </Grid>
              </Hidden>
            )}
            {/* Show this only in xs */}
            <Hidden smUp>
              <Grid
                id="xs_selector_and_btn"
                item
                xs={user_role.startsWith("client") ? 6 : 12}
              >
                <Grid
                  id="xs_selector_and_btn_container"
                  container
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid
                    id="xs_selector_or_img"
                    item
                    xs={8}
                    style={
                      user_role == "superadmin" ? { paddingTop: "15px" } : {}
                    }
                  >
                    {user_role == "superadmin" &&
                    user_role == "reselleradmin" ? (
                      <ResellerSelector
                        getClients={getClients}
                        refreshData={
                          childRef.current
                            ? childRef.current.refreshData
                            : () => {}
                        }
                        user_resellers={user_resellers}
                        reseller={reseller}
                        setReseller={setReseller}
                        userRole={user_role}
                      />
                    ) : (
                      <img
                        onClick={goHome}
                        src={client_image_url ? client_image_url : ""}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          maxHeight: "40px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Grid>
                  {user_role == "superadmin" && user_role == "reselleradmin" ? (
                    <React.Fragment>
                      <CreateResellerDialog
                        openButtonText={<EditIcon />}
                        reseller={reseller}
                        setError={setError}
                        reloadResellers={reloadResellers}
                      />
                      {user_role == "superadmin" && (
                        <CreateResellerDialog
                          setError={setError}
                          reloadResellers={reloadResellers}
                        />
                      )}
                    </React.Fragment>
                  ) : null}
                </Grid>
              </Grid>
            </Hidden>
            {/* Show this in md and lower */}
            {user_role == "superadmin" || user_role == "reselleradmin" ? (
              <Hidden lgUp>
                <Grid item sm={2} md={3} id="toogle_menu">
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleMenu}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
            ) : null}
            {/* Show this in higher than xs
            Reseller selector & Add reseller button | Client image */}
            <Hidden xsDown>
              <Grid
                item
                sm={user_role.startsWith("client") ? 2 : 6}
                lg={user_role.startsWith("client") ? 2 : 4}
                id="selector_img_and_btn"
              >
                <Grid
                  id="selector_img_and_btn_container"
                  container
                  alignItems="center"
                  // justify="space-between"
                  spacing={2}
                >
                  <Grid item id="grid_selector_or_img" xs={8}>
                    {user_role == "superadmin" ||
                    user_role == "reselleradmin" ? (
                      <ResellerSelector
                        getClients={getClients}
                        refreshData={
                          childRef.current
                            ? childRef.current.refreshData
                            : () => {}
                        }
                        user_resellers={user_resellers}
                        reseller={reseller}
                        setReseller={setReseller}
                        userRole={user_role}
                      />
                    ) : (
                      <img
                        onClick={goHome}
                        src={client_image_url ? client_image_url : ""}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          maxHeight: "50px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Grid>
                  {user_role == "superadmin" || user_role == "reselleradmin" ? (
                    <Grid item id="create_reseller_btn" xs={4}>
                      <Hidden smDown>
                        <CreateResellerDialog
                          openButtonText={<EditIcon />}
                          reseller={reseller}
                          setError={setError}
                          reloadResellers={reloadResellers}
                        />
                        {user_role == "superadmin" && (
                          <CreateResellerDialog
                            setError={setError}
                            reloadResellers={reloadResellers}
                          />
                        )}
                      </Hidden>
                      <Hidden mdUp>
                        <CreateResellerDialog
                          openButtonText={<EditIcon />}
                          reseller={reseller}
                          setError={setError}
                          reloadResellers={reloadResellers}
                        />
                        {user_role == "superadmin" && (
                          <CreateResellerDialog
                            setError={setError}
                            reloadResellers={reloadResellers}
                          />
                        )}
                      </Hidden>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Hidden>
            {/*Client selector for test's users or users to have access */}
            {(test_user ||
              (userAccessClientSelector.includes(user_role) &&
                selectorClients.length > 1)) && (
              <Hidden xsDown>
                <Grid
                  item
                  sm={4}
                  id="client-selector"
                  style={{ paddingTop: "15px" }}
                >
                  <ClientSelector
                    clients={selectorClients}
                    client={selected_client}
                    selectedClient={setSelectedClient}
                    clientData={ClientData}
                  />
                </Grid>
              </Hidden>
            )}
            {/* Appbar icons */}
            <Grid
              id="appbar_icons"
              item
              xs={user_role.startsWith("client") ? 6 : 10}
              sm={4}
              md={user_role.startsWith("client") ? 6 : 3}
            >
              <Grid container alignItems="center" justify="flex-end">
                <IconButton
                  edge="end"
                  aria-label="account"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => {
                    let prevPath = localStorage.getItem("pathname");
                    localStorage.setItem("previousPath", prevPath);
                    history.push("/app/user");
                  }}
                >
                  <AccountCircle />
                </IconButton>
                {user_role == "superadmin" ||
                user_role == "reselleradmin" ||
                user_role == "clientadmin" ||
                user_role == "clientorderapprover" ||
                user_role == "clientproductmanager" ||
                user_role == "clientusermanager" ? (
                  <IconButton
                    edge="end"
                    aria-label="account"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => history.push("/app/orders")}
                  >
                    <HistoryIcon />
                  </IconButton>
                ) : (
                  ""
                )}

                {localStorage.getItem("store_user") ||
                localStorage.getItem("client_id") ? (
                  <IconButton
                    color="inherit"
                    onClick={() => history.push("/app/store/checkout")}
                  >
                    <Badge
                      badgeContent={currentInvoice.staged_line_items.length}
                      color="secondary"
                    >
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                ) : (
                  ""
                )}
                <IconButton
                  edge="end"
                  aria-label="account"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => logout()}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {user_role != "customer" && !user_role.startsWith("client") ? (
        <React.Fragment>
          {/**Large screen menu */}
          <Hidden mdDown>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerContainer}>
                {/* Management list */}
                <List dense>
                  <ListSubheader>Management</ListSubheader>
                  <ListItem
                    button
                    onClick={() => history.push("/app/products")}
                  >
                    <ListItemIcon>
                      <ShoppingBasketIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Products"} />
                  </ListItem>
                  <ListItem button onClick={() => history.push("/app/users")}>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Users"} />
                  </ListItem>
                  <ListItem button onClick={() => history.push("/app/reports")}>
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Reports"} />
                  </ListItem>
                </List>
                <Divider />
                {/* Clients list */}
                <List dense>
                  <ListSubheader
                    style={{ lineHeight: "35px", marginBottom: "15px" }}
                  >
                    Clients
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ float: "right" }}
                      onClick={() => ShowCreateUserModal()}
                    >
                      Add +
                    </Button>
                  </ListSubheader>
                  {loading ? (
                    <ListItem button>
                      <ListItemIcon>
                        <AccountBoxIcon />
                      </ListItemIcon>
                      <Skeleton variant="rect" width={210} />
                    </ListItem>
                  ) : (
                    clients.map((client, index) => {
                      return (
                        <ListItem
                          key={index}
                          button
                          onClick={() =>
                            history.push("/app/client/" + client.id)
                          }
                          selected={
                            route_match &&
                            route_match.params.client_id == client.id
                          }
                        >
                          <ListItemIcon>
                            <AccountBoxIcon />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.clientListItem }}
                            primary={client.name}
                          />
                        </ListItem>
                      );
                    })
                  )}
                </List>
                <Divider />
              </div>
            </Drawer>
          </Hidden>
          {/**Small screen menu */}
          <Hidden lgUp>
            <Drawer
              open={smallOpen}
              onClose={toggleMenu}
              className={classes.drawer}
              variant="temporary"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerContainerSmall}>
                <List dense onClick={toggleMenu}>
                  <ListSubheader>Management</ListSubheader>
                  <ListItem
                    button
                    onClick={() => history.push("/app/products")}
                  >
                    <ListItemIcon>
                      <ShoppingBasketIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Products"} />
                  </ListItem>
                  <ListItem button onClick={() => history.push("/app/users")}>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Users"} />
                  </ListItem>
                  <ListItem button onClick={() => history.push("/app/reports")}>
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Reports"} />
                  </ListItem>
                </List>
                <Divider />
                {/* Clients list */}
                <List dense onClick={toggleMenu}>
                  <ListSubheader
                    style={{ lineHeight: "35px", marginBottom: "15px" }}
                  >
                    Clients
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ float: "right" }}
                      onClick={() => ShowCreateUserModal()}
                    >
                      Add +
                    </Button>
                  </ListSubheader>

                  {clients.map((client, index) => {
                    return (
                      <ListItem
                        key={index}
                        button
                        onClick={() => history.push("/app/client/" + client.id)}
                      >
                        <ListItemIcon>
                          <AccountBoxIcon />
                        </ListItemIcon>
                        <ListItemText primary={client.name} />
                      </ListItem>
                    );
                  })}
                </List>
                <Divider />
                {/* <List dense onClick={toggleMenu}>
              <ListSubheader
                style={{ lineHeight: "35px", marginBottom: "15px" }}
              >
                Resellers
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ float: "right" }}
                >
                  Add +
                </Button>
              </ListSubheader>
              <ListItem button>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={"Avid.io"} />
              </ListItem>
            </List> */}
              </div>
            </Drawer>
          </Hidden>
        </React.Fragment>
      ) : (
        ""
      )}
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes
            key={i}
            {...route}
            getClients={getClients}
            childRef={childRef}
            getUserCart={getUserCart}
            clients={clients}
          />
        ))}
      </Switch>
      {/*Create client Dialog*/}
      <CreateClientModal
        open={open}
        image={image}
        setImage={setImage}
        handleClose={handleClose}
        formik={formik}
        saving={saving}
      />
      {/* Show success or error */}
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
    </ThemeProvider>
  ) : (
    ""
  );
}
// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <div>
      <Route
        path={route.path}
        render={(props) => {
          // pass the sub-routes down to keep nesting
          return (
            <route.component
              {...props}
              ref={route.childRef}
              routes={route.routes}
              getClients={route.getClients}
              getUserCart={route.getUserCart}
              clients={route.clients}
            />
          );
        }}
      />
    </div>
  );
}

DashboardLayout.propTypes = {
  routes: PropTypes.array,
  location: PropTypes.object,
};
