import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * Login user
 * @param {object} values
 * @returns object
 */
export const login = async (values) => {
  const password =
    values.password == "" ? values.secret_password : values.password;
  axiosOptions.headers = {};
  axiosOptions.data = {
    query: `
      mutation {
        login(input: { identifier: "${values.user_name}", password: "${password}" }) {
          jwt
          user{
            id
            email
            username
            role{
              id
              type
            }
          }
        }
      }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response;
  } catch (error) {
    return error;
  }
};

/* Send forgot password email to user
 * @param {object} values
 * @returns object
 */
export const forgotPassword = async (email) => {
  delete axiosOptions.headers;
  axiosOptions.data = {
    query: `
      mutation {
        forgotPassword(email: "${email}") {
          ok
        }
      }    
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Reset user password
 * @param {object} values
 * @returns object
 */
export const resetPassword = async (values, code) => {
  delete axiosOptions.headers;
  axiosOptions.data = {
    query: `
    mutation {
      resetPassword(
        password:"${values.password}"
        passwordConfirmation:"${values.passwordConfirmation}"
        code:"${code}"
      ){
        user{
          id
        }
      }
    }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List users from current reseller
 * @returns object
 */
export const listUsers = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // Get user_rol
  let user_role = localStorage.getItem("role");
  let where = `where:{
    _or: [
      { role:{ ${
        user_role == "superadmin"
          ? 'type_in:["superadmin", "reselleradmin"]'
          : 'type:"reselleradmin"'
      } }, resellers: [${reseller_id}] }
      { test: true, resellers: [${reseller_id}] }
    ]
  }`;
  axiosOptions.data = {
    query: `
        query{
          users(sort: "username", ${where}){
            id
            username
            email
            confirmed
            Details
            test
            division_categories {
              id
              Name
            }
            role {
              id
              name
              type
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    // Check if data is OK
    if (response.data.data && response.data.data.users) {
      // Iterate users
      response.data.data.users.forEach((user) => {
        // Save details
        let details = user.Details;
        // Build name
        let full_name = details
          ? details.FirstName + (details.LastName ? " " + details.LastName : "")
          : "No name";
        user.name = full_name;
        let search_label =
          (full_name ? full_name + " " : "") + "(" + user.username + ")";
        user.search_label = search_label ? search_label : "";
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List 20 users from current reseller
 * @returns object
 */
export const limitListUsers = async () => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // Get user_rol
  let user_role = localStorage.getItem("role");
  let where = `where:{
    _limit: -1,
    _or: [
      { role:{ ${
        user_role == "superadmin"
          ? 'type_in:["superadmin", "reselleradmin"]'
          : 'type:"reselleradmin"'
      } }, resellers: [${reseller_id}] }
      { test: true, resellers: [${reseller_id}] }
    ]
  }`;
  axiosOptions.data = {
    query: `
        query{
            users(sort: "username", ${where}){
              id
              username
              email
              confirmed
              Details
              test
              division_categories {
                id
                Name
              }
              role {
                id
                name
                type
              }
            }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List limited users from single client
 * @param {int} client_id
 * @returns object
 */
export const limitListClientUsers = async (client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let where = `where:{
    clients: [${client_id}],
    role: { type_nin: ["superadmin", "reselleradmin"] },
    _limit: -1
  }`;
  axiosOptions.data = {
    query: `
        query{
          users(sort: "username", ${where}){
            id
            username
            email
            confirmed
            Details
            role {
              id
              name
              type
            }
            hireDate
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    if (response.data.data.users) {
      response.data.data.users.map((user) => {
        user.modify = false;
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Create user from current reseller
 * @param {object} values
 * @returns object
 */
export const createUser = async (values, testUser) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get current reseller
  let reseller_id = localStorage.getItem("reseller_id");
  axiosOptions.data = {
    query: `
      mutation{
        createUser(
          input:{
            data:{
              username:"${values.username}"
              email:"${values.email}"
              password:"${values.password}"
              confirmed:${Boolean(values.confirmed)}
              Details: {
                FirstName:"${values.first_name}"
                LastName:"${values.last_name}"
                BillingDepartment:"${values.billing_department}"
                Site:"${values.site}"
                WithoutEmail: "${values.email.length > 1 ? false : true}"
              }
              division_categories:${JSON.stringify(
                values.user_division_categories
              )}
              role:${values.role}
              clients:${JSON.stringify(values.clients ? values.clients : [])}
              resellers:${JSON.stringify([reseller_id])}
              approval_groups:${JSON.stringify(
                values.group_ids ? values.group_ids : []
              )}
              ${
                values.role == "6" || values.role == "8"
                  ? "test:false"
                  : `test:${!testUser}`
              }
              ${values.hireDate ? `hireDate:"${values.hireDate}"` : ""}
              Employee_ID: "${values.Employee_ID}"
            }
          }
        )
        {
          user {
            id
            username
            email
            confirmed
            Details
            division_categories {
              id
              Name
            }
            role{
              id
              name
            }
            hireDate
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update user
 * @param {object} values
 * @returns object
 */
export const updateUser = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        updateUser(
          input:{
            where:{id:${values.id}},
            data:{
              username:"${values.username}"
              email:"${values.email}"
              ${values.password ? `password:"${values.password}"` : ""}
              confirmed:${Boolean(values.confirmed)}
              Details: {
                FirstName:"${values.first_name}"
                LastName:"${values.last_name}"
                BillingDepartment:"${values.billing_department}"
                Site:"${values.site}"
                WithoutEmail: "${values.email.length > 1 ? false : true}"
              }
              division_categories:${JSON.stringify(
                values.user_division_categories
              )}
              role:${values.role}
              ${values.hireDate ? `hireDate:"${values.hireDate}"` : ""}
              Employee_ID: "${values.Employee_ID}"
            }
          }
        )
        {
          user {
            id
            username
            email
            confirmed
            Details
            division_categories {
              id
              Name
            }
            role{
              id
              name
            }
            hireDate
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update the stores assigned to the user
 * @param {object} values
 * @return {object}
 */
export const updateUserStores = async (values) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        updateUser(
          input:{
            where:{id:${values.id}},
            data:{
              clients:${JSON.stringify(values.clients)}
            }
          }
        )
        {
          user {
            id
            clients {
              id
            }
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

/**
 * Delete user
 * @param {int} id
 * @returns object
 */
export const deleteUser = async (id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        deleteUser(
          input:{
            where:{id:${id}},
          }
        )
        {
          user {
            id
            username
            email
            confirmed
            Details
            division_categories {
              id
              Name
            }
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List all users from single client
 * @param {int} client_id
 * @returns object
 */
export const listClientUsers = async (client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let reseller_id = localStorage.getItem("reseller_id");
  let where = `where:{
    _or:[
        {clients: [${client_id}]},
        {test: true, , resellers:[${reseller_id}]}
      ]
  }`;
  axiosOptions.data = {
    query: `
        query{
          users(sort: "username", ${where}){
            id
            username
            email
            confirmed
            Details
            test
            division_categories {
              id
              Name
              points{
                Balance
              }
            }
            role {
              id
              name
              type
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    // Check if data is OK
    if (response.data.data && response.data.data.users) {
      // Iterate users
      response.data.data.users.forEach((user) => {
        // Save details
        let details = user.Details;
        // Build name
        let full_name = details
          ? details.FirstName + (details.LastName ? " " + details.LastName : "")
          : "No name";
        user.name = full_name;
        let search_label =
          (full_name ? full_name + " " : "") + "(" + user.username + ")";
        user.search_label = search_label ? search_label : "";
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List users from single client without SuperAdmin & ResellerAdmin
 * @param {int} client_id
 * @returns object
 */
export const listRestrictedClientUsers = async (client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  let where = `where:{
    clients: [${client_id}]
    role_nin: [6, 8]
  }`;
  axiosOptions.data = {
    query: `
        query{
          users(sort: "username", ${where}){
            id
            username
            email
            confirmed
            Details
            division_categories {
              id
              Name
              points{
                Balance
              }
            }
            role {
              id
              name
              type
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    // Check if data is OK
    if (response.data.data && response.data.data.users) {
      // Iterate users
      response.data.data.users.forEach((user) => {
        // Save details
        let details = user.Details;
        // Build name
        let full_name = details
          ? details.FirstName + (details.LastName ? " " + details.LastName : "")
          : "No name";
        user.name = full_name;
        let search_label =
          (full_name ? full_name + " " : "") + "(" + user.username + ")";
        user.search_label = search_label ? search_label : "";
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Search in users from single client
 * @param {int} client_id Client ID
 * @param {array} searchTerms Array of objects {Deatails_contains: "value"}
 * @param {bool} from_store Is querying form store? (used to set filter in where)
 * @param {int} offset For paging results
 * @returns object
 */
export const searchClientUsers = async (
  client_id = false,
  searchTerms,
  from_store = false,
  offset = null
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get user_rol
  let user_role = localStorage.getItem("role");
  //Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  // Filters for users list
  let where_list = `
    where: {
      clients: [${client_id}]
      resellers: [${reseller_id}]
      role_nin: [6, 8]
      _or: [
        ${searchTerms.map((term) => {
          return JSON.stringify(term).replace(/"(\w+)"\s*:/g, "$1:");
        })}
        ${searchTerms.length === 0 ? `{ Details_contains: "" }` : ""}
        ${
          user_role == "superadmin" || user_role == "reselleradmin"
            ? `{test: true}`
            : ""
        }
        
      ]
    }
  `;
  // Filter for store
  let or_props = { clients: [client_id], role_in: [7] };
  let where_store = `
    where: {
      resellers: [${reseller_id}],
      _or: [
        ${
          searchTerms.length === 0
            ? `{ clients: [${client_id}], role_in: [7] }`
            : ""
        }
        ${searchTerms.map((term) => {
          Object.assign(term, or_props);
          return JSON.stringify(term).replace(/"(\w+)"\s*:/g, "$1:");
        })}
        ${
          user_role == "superadmin" || user_role == "reselleradmin"
            ? `{ test: true }`
            : ""
        }
        
      ]
    }
  `;
  axiosOptions.data = {
    query: `
        query{
          users(
            ${offset !== null ? `limit: 20, start: ${offset},` : ``}
            sort: "username",
            ${from_store ? where_store : where_list}
          ){
            id
            username
            email
            confirmed
            Details
            division_categories {
              id
              Name
              points{
                Balance
              }
            }
            role {
              id
              name
              type
            }
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    // Check if data is OK
    if (response.data.data && response.data.data.users) {
      // Iterate users
      response.data.data.users.forEach((user) => {
        // Save details
        let details = user.Details;
        // Build name
        let full_name = details
          ? details.FirstName + (details.LastName ? " " + details.LastName : "")
          : "No name";
        user.name = full_name;
        let search_label =
          (full_name ? full_name + " " : "") + "(" + user.username + ")";
        user.search_label = search_label ? search_label : "";
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Search users from single client approval group list
 * @param {int} client_id Client ID
 * @param {array} searchTerms Array of objects {Deatails_contains: "value"}
 * @returns object
 */
export const searchClientApprovalGroupUsers = async (
  client_id = false,
  searchTerms
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get user_rol
  let user_role = localStorage.getItem("role");
  // Check if session user has Admin privileges
  let isAdmin = user_role == "superadmin" || user_role == "reselleradmin";
  //Get reseller from current user
  let reseller_id = localStorage.getItem("reseller_id");
  let terms = searchTerms ? searchTerms.split(" ") : [];
  let searchArray = [];
  terms.forEach((word) => {
    let searchObj = { Details_contains: word };
    searchArray.push(searchObj);
  });
  // Filters for users list
  let where_list = `
    where: {
      resellers: ${reseller_id}
      role_nin: [6, 8]
      _or: [
        ${
          searchArray.length === 0
            ? isAdmin
              ? `{ clients: ${client_id} }, { test: true }`
              : `{ clients: ${client_id} }`
            : ""
        }
        ${searchArray.map(({ ...term }) => {
          term.clients = client_id;
          return JSON.stringify(term).replace(/"(\w+)"\s*:/g, "$1:");
        })}
        ${
          isAdmin
            ? searchArray.map(({ ...term }) => {
                term.test = true;
                return JSON.stringify(term).replace(/"(\w+)"\s*:/g, "$1:");
              })
            : ""
        }
      ]
    }
  `;
  axiosOptions.data = {
    query: `
        query {
          users (
            sort: "username",
            ${where_list}
          ){
            id
            username
            email
            confirmed
            Details
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    // Check if data is OK
    if (response.data.data && response.data.data.users) {
      // Iterate users
      response.data.data.users.forEach((user) => {
        // Save details
        let details = user.Details;
        // Build name
        let full_name = details
          ? details.FirstName + (details.LastName ? " " + details.LastName : "")
          : "No name";
        user.name = full_name;
        let search_label =
          (full_name ? full_name + " " : "") + "(" + user.username + ")";
        user.search_label = search_label ? search_label : "";
      });
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

export const listUserDivisioCategories = async (user_id, client) => {
  let client_id = client ? client : localStorage.getItem("client_id");
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    query{
      user(id:${user_id}){
        division_categories(where:{clients:[${client_id}]}){
          id
          Name
          product_division_categories(where:{client:{id:${client_id}}}){
            id
            division:division_category{
              id
              Name
            }
          }
          points (where: { user_id:${user_id} }) {
            user_id{
              id
            }
            Balance
          }
        }
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get the last points registered by division category
 * @param {*} userId
 * @param {*} divisionCategory
 * @returns
 */
export const getUserDivisionCategoryBalance = async (
  userId,
  divisionCategory
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  axiosOptions.data = {
    query: `
      query{
        points(
            limit:1,
            sort:"id:DESC",
            where:{user_id:${userId}, division_category:{id:${divisionCategory}}}
          ){
          id
          Balance
          TransactionAmount
          Message
          division_category{
            id
          }
        }
      }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get User by ID
 * @param {id} user_id
 * @returns {Object} Object
 */
export const getUserById = async (user_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  axiosOptions.data = {
    query: `
    query{
      user(id:${user_id}){
        id
        username
        email
        Details
        confirmed
        test
        clients{
          id
          name
          subdomain
          options: division_categories {
            id
            Name
            points( where:{ user_id:[ ${user_id} ], _limit: -1 } ) {
              id
              Balance
          }
          }
        }
        role{
          id
          type
        }
        division_categories{
          id
          Name
          points( where:{ user_id: [${user_id}], _limit: -1 } ){
            id
            Balance
          }
          clients {
            id
          }
        }
        approval_groups{
          id
          Name
        }
        hireDate
        Employee_ID
      }
    }
  `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update user
 * @param {object} values
 * @returns object
 */
export const updateUserById = async (values, disable) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        updateUser(
          input:{
            where:{id:${values.id}},
            data:{
              username:"${
                values.usernameWithPrefix
                  ? values.usernameWithPrefix
                  : values.username
              }"
              role:${values.role}
              email:"${values.email}"
              ${disable ? `confirmed:${Boolean(values.confirmed)}` : ""}
              ${values.password ? `password:"${values.password}"` : ""}
              ${
                values.role == "6" || values.role == "8"
                  ? "test:false"
                  : `test:${Boolean(values.test)}`
              }
              Details: {
                FirstName:"${values.first_name}"
                LastName:"${values.last_name}"
                BillingDepartment:"${values.billing_department}"
                Site:"${values.site}"
                WithoutEmail: "${values.email.length > 1 ? false : true}"
              }
              ${
                "group_ids" in values
                  ? `approval_groups: ${JSON.stringify(values.group_ids)}`
                  : ""
              }
              ${values.hireDate ? `hireDate: "${values.hireDate}"` : ""}
              Employee_ID: "${values.Employee_ID}"
            }
          }
        )
        {
          user {
            id
            username
            email
            confirmed
            Details
            division_categories {
              id
              Name
            }
            role{
              id
              name
            }
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Update user division categories
 * @param {object} values
 * @returns object
 */
export const updateUserDivisionCategories = async (id, division_categories) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      mutation{
        updateUser(
          input:{
            where:{id:${id}},
            data:{
              division_categories:${JSON.stringify(division_categories)}
            }
          }
        )
        {
          user {
            division_categories {
              id
              Name
            }
          }
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List all users from single client
 * @param {Int} clientId 'ID' id Field on the table clients
 * @param {Array} userIds 'ID' id Field on the table users
 * @returns object
 */
export const getAllUsersFromClient = async (clientId, userIds) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
    query {
      getClientUsersCSVData(clientId: ${clientId}, userIds:${JSON.stringify(
      userIds
    )}) {
        ID
        First_Name
        Last_Name
        Password
        Employee_ID
        Email
        Billing_Department
        Site
        Division_Categories
        Role
        Group_id
        Hire_date
      }
    }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Create email queue for export users
 * @param {Int} clientId 'ID' id Field on the table clients
 * @param {Array} userIds 'ID' id Field on the table users
 * @returns object
 */
export const createExportUsersQueue = async (
  clientId,
  userIds,
  emailType = "export_users"
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Generate options
  const options = {
    reseller_id: localStorage.getItem("reseller_id"),
    email_type: "export_users",
    client_id: clientId,
    user_ids: userIds,
    user_id: localStorage.getItem("user"),
  };
  // Set data
  axiosOptions.data = {
    query: `
      mutation {
        createEmailQueue(
          input: { 
            data: { 
              options: ${JSON.stringify(options).replace(/"(\w+)"\s*:/g, "$1:")}
              send_date: "${new Date().toISOString()}"
              users_permissions_user: ${options.user_id}
              client: ${clientId}
              email_type:${emailType}
            } 
          }
        ) {
          emailQueue {
            id
            options
            status
            send_date
            sent
            queued
            error
          }
        }
      }
    `,
  };
  try {
    // Send request
    const response = await axios(axiosOptions);
    // Return response
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Disable users by id
 * @param {object} value users_id/confirmed
 * @returns {Object} object
 */
export const ModifyUsersByIds = async (value) => {
  let axiosOption = {
    url: `${process.env.REACT_APP_REST_URL}/users-permissions/users/modify`,
    method: "PUT",
  };

  axiosOption.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  axiosOption.data = value;
  try {
    const response = await axios(axiosOption);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Purpose: Public query to check if user is for testing
 * @param {*} user_id User ID
 * @param {*} username Username
 * @returns boolean {isTestUser}
 */
export const IsTestUser = async (user_id = null, username = null) => {
  // Remove headers, we don't need them because this is a public query
  delete axiosOptions.headers;
  axiosOptions.data = {
    query: `
      query {
        isTestUser(user_id:${user_id}, username:"${username}")
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data.data.isTestUser;
  } catch (error) {
    return error;
  }
};

/**
 * Check if user is for testing
 * @param {int} user_id
 * @returns object
 */
export const checkTestingUser = async (user_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      query {
        user(id: ${user_id}){
          id
          test
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    if (response.data.data.user.test) {
      return response.data.data.user.test;
    }
    return false;
  } catch (error) {
    return false;
  }
};

/**
 * Get User Approval Groups
 * @param {id} user_id
 * @returns {Object} Object
 */
export const getUserApprovalGroups = async (user_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      query {
        user(id: ${user_id}) {
          id
          username
          email
          approval_groups{
            id
          }
        }
      }
  `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * POST import Users
 * @param {object} value users_id/confirmed
 * @returns {Object} object
 */
export const ImportUsers = async (value) => {
  let formData = new FormData();
  // Get file
  if (value.files) {
    formData.append(`files.${value.files.name}`, value.files);
  }
  // Get client id
  if (value.clientId) {
    formData.append("clientId", value.clientId);
  }
  let axiosOption = {
    url: `${process.env.REACT_APP_REST_URL}/users/import`,
    method: "POST",
    data: formData,
  };

  axiosOption.headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  //axiosOption.data = value;
  try {
    const response = await axios(axiosOption);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * List Users from single client or reseller for table view
 * @param {Int} client_id Client ID
 * @param {Int} reseller_id Reseller ID
 * @returns {Array} Array Users
 */
export const getAllUsersForTableView = async (
  client_id = null,
  reseller_id = null
) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  axiosOptions.data = {
    query: `
      query {
        getUsersTable(${
          reseller_id ? "reseller_id:" + reseller_id : "client_id:" + client_id
        }) {
          id
          Name
          Billing_Department
          Status
          Role
          Hire_date
          Control_column
          Employee_ID
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get email queue status
 * @param {int} clientId Client ID
 * @param {string} emailType Email type
 * @returns object {emailQueue}
 */
export const getEmailQueue = async (clientId, emailType = "export_users") => {
  // Set headers
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Get user id
  const userId = localStorage.getItem("user");
  // Set data
  axiosOptions.data = {
    query: `
      query {
         emailQueues(
          sort: "id:DESC"
          start: 0
          limit: 1
          where: { 
            users_permissions_user: ${userId}, 
            client: ${clientId},
            email_type: "${emailType}",
            viewed:false,
          }
      ) {
        id
        status
        sent
        error
      }
    }`,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return false;
  }
};

/**
 * Mark email queue as viewed
 * @param {Int} emailQueueId Email Queue ID
 * @returns object {emailQueue}
 */
export const markAsViewed = async (emailQueueId) => {
  // Set headers
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  // Set data
  axiosOptions.data = {
    query: `
    mutation {
      updateEmailQueue(input: { where: { id: ${emailQueueId} }, data: { viewed: true } }) {
        emailQueue {
          id
          viewed
        }
      }
    }`,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return false;
  }
};
