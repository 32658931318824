// Libraries
import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

// API
import { states } from "../../api/States";
import { countries } from "../../api/Countries";
import { provinces } from "../../api/CanadianProvinces";

// Main component
const ShippingDetails = ({
  formik,
  show_form,
  setShowForm,
  customAddresses,
  customers,
  loading_adresses,
  setCanadaSelected,
}) => {
  // View
  return (
    <Paper id="paper_shipping" elevation={3}>
      <Typography variant="h5" gutterBottom>
        <Box fontWeight="fontWeightBold">Shipping Details </Box>
      </Typography>
      <Divider />
      {/* Customer */}
      {customers.length > 0 ? (
        <Grid item xs={12} md={6}>
          <FormControl
            id="customer_form_control"
            // variant="outlined"
            size="small"
            margin="none"
            fullWidth
            error={formik.touched.customer && Boolean(formik.errors.customer)}
          >
            <InputLabel id="customer_label">Shipping Details</InputLabel>
            <Select
              labelId="customer_label"
              label="Shipping Details"
              id="customer"
              name="customer"
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              }}
              value={formik.values.customer}
              onChange={(event) => {
                setShowForm(event.target.value === 0);
                formik.handleChange(event);
              }}
              disabled={loading_adresses}
              startAdornment={
                loading_adresses ? (
                  <InputAdornment>
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ) : null
              }
            >
              <MenuItem value={0} disabled={!customAddresses ? true : false}>
                <i>(Create new shipping details)</i>
              </MenuItem>
              {customers.map((customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                  {customer.FirstName || ""} {customer.LastName || ""} (
                  {customer.address.Street || "" + " "}
                  {customer.address.City || "" + ", "}
                  {customer.address.State || "" + " "}
                  {customer.address.Zip || "" + " "}
                  {customer.address.Country || "" + " "}
                  {customer.ConfirmationEmail || "" + " "}
                  {customer.Phone})
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {formik.touched.customer && formik.errors.customer}
            </FormHelperText>
          </FormControl>
        </Grid>
      ) : null}
      <Grid id="grid_container_shipping_fields" container spacing={2}>
        {show_form && customAddresses ? (
          <React.Fragment>
            {/* First name */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                autoFocus
                id="first_name"
                margin="none"
                label="First Name *"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Grid>
            {/* Last name */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                id="last_name"
                margin="none"
                label="Last Name *"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            {/* Email */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                id="email"
                type="email"
                margin="none"
                label="Confirmation Email *"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            {/* Phone */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                id="phone"
                type="tel"
                margin="none"
                label="Phone *"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            {/* Address */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                id="address"
                margin="none"
                label="Address *"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.address}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            {/* Address 2 */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                id="second_address"
                margin="none"
                label="Address 2 (Optional)"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.second_address}
              />
            </Grid>
            {/* City */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                id="city"
                margin="none"
                label="City *"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.city}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            {/* State */}
            <Grid item xs={12} md={4}>
              <FormControl
                id="state_form_control"
                // variant="outlined"
                size="small"
                margin="none"
                fullWidth
                error={formik.touched.state && Boolean(formik.errors.state)}
              >
                <InputLabel id="state_label">
                  {formik.values.country === "CA" ? "Province *" : "State *"}
                </InputLabel>
                <Select
                  labelId="state_label"
                  label="State"
                  id="state"
                  name="state"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  }}
                  value={formik.values.state}
                  onChange={formik.handleChange}
                >
                  {states.length == 0 ? (
                    <MenuItem disabled value={0}>
                      No options
                    </MenuItem>
                  ) : null}
                  {(formik.values.country === "CA" ? provinces : states).map(
                    (state) => (
                      <MenuItem
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </MenuItem>
                    )
                  )}
                </Select>
                <FormHelperText>
                  {formik.touched.state && formik.errors.state}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* Postal Code */}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="postal_code"
                type="text"
                inputProps={{ min: 1 }}
                size="small"
                margin="none"
                label="Postal Code *"
                // variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.postal_code}
                error={
                  formik.touched.postal_code &&
                  Boolean(formik.errors.postal_code)
                }
                helperText={
                  formik.touched.postal_code && formik.errors.postal_code
                }
              />
            </Grid>
            {/* Country */}
            <Grid item xs={12} md={4}>
              <FormControl
                id="country_form_control"
                // variant="outlined"
                size="small"
                margin="none"
                fullWidth
                error={formik.touched.country && Boolean(formik.errors.country)}
              >
                <InputLabel id="country_label">Country *</InputLabel>
                <Select
                  labelId="country_label"
                  label="Country"
                  id="country"
                  name="country"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  }}
                  value={formik.values.country}
                  onChange={(event) => {
                    // Handle the country change
                    formik.handleChange(event);
                    // Reset the state value
                    formik.setFieldValue("state", "");
                    // Reset the postal_code value
                    formik.setFieldValue("postal_code", "");
                    // Set the value of canadaSelected
                    setCanadaSelected(event.target.value == "CA");
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem
                      key={country.abbreviation}
                      value={country.abbreviation}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.country && formik.errors.country}
                </FormHelperText>
              </FormControl>
            </Grid>
          </React.Fragment>
        ) : !customAddresses && customers.length == 0 ? (
          <Typography
            variant="h6"
            gutterBottom
            style={{ paddingLeft: "10px", marginTop: "20px" }}
          >
            {"This client does not allow the user to enter addresses"}
          </Typography>
        ) : null}
        {/* Note */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            id="note"
            margin="none"
            label="Note"
            // variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.note}
            disabled={!customAddresses && customers == 0 ? true : false}
            // multiline
            // rows={2}
            // rowsMax={4}
          />
        </Grid>
        {/* .......... */}
      </Grid>
      {/* .......... */}
    </Paper>
  );
};

ShippingDetails.propTypes = {
  formik: PropTypes.object,
  show_form: PropTypes.bool,
  setShowForm: PropTypes.func,
  customAddresses: PropTypes.bool,
  clientUser: PropTypes.string,
  setAddresses: PropTypes.func,
  customers: PropTypes.array,
  loading_adresses: PropTypes.bool,
  setCanadaSelected: PropTypes.func,
};

export default ShippingDetails;
