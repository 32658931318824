import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  makeStyles,
  Hidden,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

//Api
import { listRoles } from "../../api/Roles";
import { getUserById, login, updateUserById } from "../../api/Users";
import { getClientOverviewInfo } from "../../api/Clients";
import { listApprovalGroups } from "../../api/ApprovalGroups";

// Custom components
import SnackbarMessage from "../SnackbarMessage";
import PasswordDialog from "./PasswordDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",
    padding: "20px",
    minHeight: 330,
    //maxHeight: 330,
  },
  form: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  ButtonsContainers: {
    marginTop: "10px",
  },
  Button: {
    [theme.breakpoints.up("xs")]: {
      float: "right",
    },
  },
  datePickerIconbutton: {
    "& div": {
      "& div": {
        "& > button": {
          marginRight: "-15px",
        },
      },
    },
  },
}));

export default function UserContainer(props) {
  const { user_id, view, fromStore, client_id, disableLabes } = props;
  const classes = useStyles();
  const [user, setUser] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const role = view;
  const userSessionRole = localStorage.getItem("role");
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [roles, setRoles] = useState([]);
  const [disableEdit, setDisableEdit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [load_approval_groups, setLoadApprovalGroups] = useState(false);
  const [RoleUserToUpdate, setRoleUserToUpdate] = useState("");
  const [userPrefix, setUserPrefix] = useState("");
  const [approval_groups, setApprovalGroups] = useState([]);
  let location = useLocation();
  // Check if url contains "client" param
  const { client } = useParams();
  // Chek if you come from global user list
  const route_global_user = useRouteMatch("/app/user/:id");
  const routeClientUser = useRouteMatch("/app/client/:client/user/:id");
  const userSessionId = localStorage.getItem("user");
  // Manage errors
  const [error, setError] = useState({
    message: "",
    severity: "error",
    open: false,
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  // Initial user data
  useEffect(() => {
    //Get initial user data
    getUser();
  }, []);

  // List approval groups
  useEffect(() => {
    if (props.clients.length) {
      const client_from_url = props.clients.find(
        (single_client) => single_client.id == client
      );
      // Set if need to load approval groups
      let load_approval_groups =
        (route_global_user && route_global_user.isExact) ||
        (client_from_url &&
          client_from_url.mode &&
          client_from_url.mode === "Approval");
      setLoadApprovalGroups(load_approval_groups);
      // List approval groups
      if (load_approval_groups) {
        listApprovalGroups(client)
          .then((result) => {
            if (result && result.data && result.data.approvalGroups) {
              setApprovalGroups(result.data.approvalGroups);
            } else {
              console.log("Error listing approval groups", error);
            }
          })
          .catch((error) => {
            console.log("Error listing approval groups", error);
          });
      }
    }
  }, [props.clients]);

  const initialUserValues = {
    id: "",
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    billing_department: "",
    site: "",
    role: "",
    test: false,
    group_ids: 0,
    hireDate: null,
    Employee_ID: "",
  };

  //Get user data
  const getUser = () => {
    setLoading(true);
    if (user_id) {
      getUserById(user_id).then((xhr) => {
        if (xhr.data) {
          const user = xhr.data.user;
          user.group_ids =
            user.approval_groups &&
            Array.isArray(user.approval_groups) &&
            user.approval_groups.length > 0
              ? user.approval_groups[0].id
              : 0;
          //get the prefix for customer users
          if (props.clients && props.clients.length) {
            const currentClient = props.clients.find(
              (client) => client.id == client_id
            );
            let prefix =
              currentClient && currentClient.subdomain
                ? currentClient.subdomain + "-"
                : "";
            setUserPrefix(prefix);
            if (user.role.id == 7) {
              user.username = user.username.includes(prefix)
                ? user.username.substring(prefix.length)
                : user.username;
            }
            initializeValues(user);
          } else {
            getClientOverviewInfo(client_id).then((result) => {
              let prefix = result.subdomain + "-";
              setUserPrefix(prefix);
              if (user.role.id == 7) {
                user.username = user.username.includes(prefix)
                  ? user.username.substring(prefix.length)
                  : user.username;
              }
              initializeValues(user);
            });
          }
        }
      });
    }
  };

  const updateUser = (values) => {
    setSaving(true);
    if (values.password == "") {
      delete values.password;
    }
    // Removing spaces from the user
    const properties = ["first_name", "last_name", "username", "Employee_ID"];
    for (const property of properties) {
      if (`${property}` in values) {
        values[property] = values[property].trim();
      }
    }
    //Only users below superadmin and reselleradmin will be tested
    if (location.pathname.includes("/app/user/")) {
      if (values.role == 6 || values.role == 8) {
        values.test = false;
      } else {
        values.test = true;
      }
    } else {
      if (values.role == 7) {
        if (!values.username.includes(userPrefix))
          values.usernameWithPrefix = userPrefix + values.username;
      }
    }
    updateUserById(values)
      .then((results) => {
        if (!results.data) {
          setError({
            open: true,
            message: `An error occurred, please try again`,
            severity: "error",
          });
        } else {
          if (results.errors) {
            // Display error
            let error_message =
              Array.isArray(results.errors) &&
              results.errors.length > 0 &&
              "message" in results.errors[0]
                ? results.errors[0].message
                : `An error occurred, please try again`;
            setError({
              open: true,
              message: error_message,
              severity: "error",
            });
          } else {
            getUser();
            setError({
              open: true,
              message: `User Updated Successfully`,
              severity: "success",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setSaving(false));
  };

  const initializeValues = (user) => {
    initialUserValues.id = user.id;
    user.role = user.role.id;
    initialUserValues.role = user.role;
    initialUserValues.first_name = user.Details
      ? user.Details.FirstName
        ? user.Details.FirstName.trim()
        : ""
      : "";
    initialUserValues.last_name = user.Details
      ? user.Details.LastName
        ? user.Details.LastName.trim()
        : ""
      : "";
    initialUserValues.billing_department = user.Details
      ? user.Details.BillingDepartment
        ? user.Details.BillingDepartment
        : ""
      : "";
    initialUserValues.site = user.Details
      ? user.Details.Site
        ? user.Details.Site
        : ""
      : "";
    initialUserValues.username = user.username.trim();
    initialUserValues.group_ids = user.group_ids;
    initialUserValues.email =
      user?.Details?.WithoutEmail == "true" ? "" : user.email;
    initialUserValues.test = user.test;
    initialUserValues.hireDate = user.hireDate
      ? moment(user.hireDate, "YYYY-MM-DD").format("MM/DD/YYYY")
      : null;
    initialUserValues.Employee_ID = user.Employee_ID;
    formik.setValues(initialUserValues);
    setUser(user);
    setRolesEdit(user);
    setRoleUserToUpdate(user.role);
    setLoading(false);
  };

  /**
   * Purpose: Set the roles that the current user can assign
   * @param {*} user User object
   */
  const setRolesEdit = async (user) => {
    // Get role from local storage
    const role = localStorage.getItem("role");
    if (role != "clientorderapprover" && role != "customer") {
      // List roles
      listRoles()
        .then(async (result) => {
          // Get roles
          let roles = result?.data?.roles || [];
          //filter roles
          if (location.pathname.includes("client")) {
            roles = roles.filter((role) => role.id != 6 && role.id != 8);
          }
          //set the roles that the current user can give
          await getRolesThatUserInSessionCanAssign(role, roles);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // set if the current user can edit selected user role
    await getRolesThatUserInSessionCanAssign(role, null, user.role);
  };

  /**
   * Purpose: Determine the roles that the user in session can assign
   * @param {String} userRoleName role name of the user in session
   * @param {Array} roles List of roles
   * @param {Number} userRoleId Id of the user in session
   * @returns Boolean value
   */
  const getRolesThatUserInSessionCanAssign = async (
    userRoleName = localStorage.getItem("role"),
    roles = [],
    userRoleId = null
  ) => {
    const userRoles = {
      superadmin: [6, 8, 9, 5, 3, 4, 7],
      reselleradmin: [9, 5, 3, 4, 7],
      clientadmin: [5, 3, 4, 7],
      clientproductmanager: [3, 4, 7],
      clientusermanager: [4, 7],
      clientorderapprover: [7],
      customer: [],
    };
    // Check if userRoleName is in userRoles
    if (userRoleName in userRoles) {
      const rolesToAssign = userRoles[userRoleName];
      // Check if roles is an array
      if (Array.isArray(roles) && roles.length > 0) {
        // Iterate over roles
        roles.forEach((role) => {
          // Check if role is in rolesToAssign
          if (rolesToAssign.includes(Number(role.id))) {
            role.disabled = false;
          } else {
            role.disabled = true;
          }
        });
        // Set roles
        setRoles(roles);
      } else if (userRoleId) {
        // Check if userRoleId is in rolesToAssign
        if (rolesToAssign.includes(Number(userRoleId))) {
          setDisableEdit(false);
        } else {
          setDisableEdit(true);
        }
      }
    }
  };

  const Cancel = () => {
    if (fromStore) {
      history.push(
        location.pathname.includes("store")
          ? `/app/client/${client_id}/store/products`
          : !location.pathname.includes("client")
          ? `/app/users`
          : props.route_user && props.route_user.isExact
          ? `/app/client/${client_id}/users`
          : ""
      );
    } else {
      let previous_path = localStorage.getItem("previousPath");
      let routes = {
        superadmin: `/app/products`,
        reselleradmin: `/app/products`,
        clientadmin: `/app/client/${client_id}`,
        clientproductmanager: `/app/client/${client_id}/products`,
        clientusermanager: `/app/client/${client_id}/users`,
        clientorderapprover: `/app/client/${client_id}/approval`,
        customer: `/store/products`,
      };
      let path = previous_path ? previous_path : routes[userSessionRole];
      history.push(path);
    }
  };

  /**
   * Formik validation schema
   */
  const validationSchema = yup.object({
    first_name: yup.string("First name").required("First Name is required"),
    last_name: yup.string("Last name").required("Last name is requiered"),
    username: yup.string("Username").required("User name is requiered"),
    email: yup
      .string("Email")
      .required("Email is requiered")
      .email("Enter a valid email"),
    group_ids: yup.number(),
    hireDate: yup.date("Hire Date").nullable(),
    Employee_ID: yup.string("Employee ID"),
  });

  /**
   * Use formik user
   */
  const formik = useFormik({
    initialValues: initialUserValues,
    validationSchema: validationSchema,
    onSubmit: (original_values) => {
      let values = { ...original_values };
      // Validate hire date
      if (values.hireDate) {
        values.hireDate = moment(values.hireDate).format("YYYY-MM-DD");
      }
      // In case we changing customer to another role
      if (RoleUserToUpdate == "7" && RoleUserToUpdate != values.role) {
        // Check if customer is using default password
        login({
          user_name: userPrefix + values.username,
          password: "uHFLoLmlcqg1&y!KhbnQW9LIoI*a9w3O",
        }).then((response) => {
          // If it is, we need to assign a new password
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.login
          ) {
            setOpenDialog(true);
          } else {
            // Otherwise, preserve the customer password and just update it
            updateUser(values);
          }
        });
      } else {
        // Update use data
        updateUser(values);
      }
    },
  });

  //Open dialog
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  //Close dialog
  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <div>
      <Paper variant="outlined" className={classes.paper}>
        {/**User profile title*/}
        <Typography variant="h6" gutterBottom style={{ paddingLeft: "10px" }}>
          User profile
        </Typography>
        <form onSubmit={formik.handleSubmit} className={classes.form} key={1}>
          {loading ? (
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Skeleton height={50} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton height={50} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton height={50} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton height={50} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton height={50} />
              </Grid>
              {role != "customer" && role != "clientorderapprover" ? (
                <Grid item xs={12} md={6}>
                  <Skeleton height={50} />
                </Grid>
              ) : (
                ""
              )}
              {routeClientUser &&
                routeClientUser.isExact &&
                userSessionId !== user_id && (
                  <Grid item xs={12} md={6}>
                    <Skeleton height={50} />
                  </Grid>
                )}
            </Grid>
          ) : (
            <Grid container spacing={1}>
              {/**First Name*/}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="first_name"
                  margin="dense"
                  label="First Name"
                  variant="outlined"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  disabled={disableLabes}
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                />
              </Grid>
              {/**Last Name*/}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="last_name"
                  margin="dense"
                  label="Last Name"
                  variant="outlined"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  disabled={disableLabes}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                />
              </Grid>
              {/**User Name*/}
              <Grid item xs={12} md={6}>
                <TextField
                  InputProps={{
                    startAdornment:
                      formik.values.role == "7" &&
                      !location.pathname.includes("/app/user/") ? (
                        <InputAdornment position="start">
                          {userPrefix}
                        </InputAdornment>
                      ) : (
                        ""
                      ),
                    endAdornment:
                      formik.values.role == "7" &&
                      !location.pathname.includes("/app/user/") ? (
                        <Hidden xsDown>
                          <InputAdornment position="end">
                            <Button
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  userPrefix + formik.values.username
                                );
                              }}
                            >
                              copy
                            </Button>
                          </InputAdornment>
                        </Hidden>
                      ) : (
                        ""
                      ),
                  }}
                  fullWidth
                  size="small"
                  id="username"
                  margin="dense"
                  label="User Name"
                  variant="outlined"
                  disabled={
                    userSessionRole == "customer" ? true : false || disableLabes
                  }
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                />
              </Grid>
              {/**Email*/}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="email"
                  margin="dense"
                  label="Email"
                  variant="outlined"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  disabled={disableLabes}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              {/** Role */}
              {role != "customer" && role != "clientorderapprover" ? (
                <Grid item xs={12} md={6}>
                  <FormControl
                    id="role_form_control"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    error={formik.touched.role && Boolean(formik.errors.role)}
                  >
                    <InputLabel id="role_label">Role</InputLabel>
                    <Select
                      id="role_select"
                      labelId="role_label"
                      label="Role"
                      name="role"
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                      }}
                      disabled={
                        location.pathname == "/app/user" &&
                        props.isAdmin &&
                        !disableLabes
                          ? false
                          : disableEdit
                          ? true
                          : false
                      }
                      value={formik.values.role}
                      onChange={(event, child) => {
                        formik.handleChange(event, child);
                      }}
                    >
                      {roles.map((role) => (
                        <MenuItem
                          key={role.id}
                          value={role.id}
                          disabled={role.disabled}
                        >
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.role && formik.errors.role}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}
              {/**Billing Department*/}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="billing_department"
                  margin="dense"
                  label="Billing Department"
                  variant="outlined"
                  value={formik.values.billing_department}
                  onChange={formik.handleChange}
                  disabled={disableLabes}
                  error={
                    formik.touched.billing_department &&
                    Boolean(formik.errors.billing_department)
                  }
                  helperText={
                    formik.touched.billing_department &&
                    formik.errors.billing_department
                  }
                />
              </Grid>
              {/* Approval group */}
              {load_approval_groups ? (
                <Grid item xs={12} md={6}>
                  <FormControl
                    id="approval_group_form_control"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                    error={
                      formik.touched.group_ids &&
                      Boolean(formik.errors.group_ids)
                    }
                  >
                    <InputLabel id="approval_group_label">
                      Approval Group
                    </InputLabel>
                    <Select
                      id="approval_group_select"
                      labelId="approval_group_label"
                      label="Approval Group"
                      name="group_ids"
                      disabled={approval_groups.length == 0}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                      }}
                      value={formik.values.group_ids}
                      onChange={(event, child) => {
                        formik.handleChange(event, child);
                      }}
                    >
                      <MenuItem value={0}>None</MenuItem>
                      {approval_groups.length > 0 ? (
                        approval_groups.map((approval_group) => (
                          <MenuItem
                            key={approval_group.id}
                            value={approval_group.id}
                          >
                            {approval_group.Name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value={0} disabled>
                          No approval groups
                        </MenuItem>
                      )}
                    </Select>
                    <FormHelperText>
                      {formik.touched.group_ids && formik.errors.group_ids}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : null}
              {/** Hire Date */}
              {routeClientUser &&
                routeClientUser.isExact &&
                userSessionId !== user_id && (
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.datePickerIconbutton}
                        fullWidth
                        inputVariant="outlined"
                        size="small"
                        margin="dense"
                        maxDate={new Date()}
                        placeholder="mm/dd/yyyy"
                        id="date-picker-dialog"
                        label="Hire Date"
                        format="MM/dd/yyyy"
                        value={formik.values.hireDate}
                        onChange={(date) => {
                          formik.handleChange({
                            target: { name: "hireDate", value: date },
                          });
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={disableLabes || disableEdit}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                )}
              {/**Employee_ID*/}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="Employee_ID"
                  margin="dense"
                  label="Employee ID"
                  variant="outlined"
                  value={formik.values.Employee_ID}
                  onChange={formik.handleChange}
                  disabled={disableLabes}
                  error={
                    formik.touched.Employee_ID &&
                    Boolean(formik.errors.Employee_ID)
                  }
                  helperText={
                    formik.touched.Employee_ID && formik.errors.Employee_ID
                  }
                />
              </Grid>
            </Grid>
          )}
          {/**Buttons container*/}
          <Grid container spacing={2} className={classes.ButtonsContainers}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
              >
                CHANGE PASSWORD
              </Button>
            </Grid>
            <Hidden lgDown>
              <Grid item md={2} />
            </Hidden>
            <Grid item xs={6} sm={3} md={3} lg={2}>
              <Button onClick={Cancel} variant="contained" fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                className={classes.Button}
                disabled={saving || disableLabes}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {/** Open Password Dialog */}
      <PasswordDialog
        open={openDialog}
        handleClose={handleClose}
        user={user}
        getUser={getUser}
        currentRole={RoleUserToUpdate}
        userPrefix={userPrefix}
        newRole={formik.values.role}
      />
      {/* Show success or error */}
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
    </div>
  );
}

UserContainer.propTypes = {
  user_id: PropTypes.string,
  view: PropTypes.string,
  fromStore: PropTypes.bool,
  client_id: PropTypes.string,
  isAdmin: PropTypes.bool,
  disableLabes: PropTypes.bool,
  route_user: PropTypes.object,
  clients: PropTypes.array,
};
